* {
    font-family: sans-serif;
    color: white;
    margin: 0;
}

html, body, canvas {
    height: 100%;
    width: 100%;
}

#fps {
    top: 1rem;
    right: 1rem;
    position: absolute;
    padding: 0;
    font-size: 1.5rem;
}