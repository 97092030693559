* {
  color: #fff;
  margin: 0;
  font-family: sans-serif;
}

html, body, canvas {
  height: 100%;
  width: 100%;
}

#fps {
  padding: 0;
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

/*# sourceMappingURL=index.31900e4b.css.map */
